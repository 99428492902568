import React from 'react'
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

const FourOhFourStyles = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 100vw;
    .header__logo {
        position: absolute;
        top: 48px;
    }
    a {
        display: block;
        text-decoration: underline;
    }
`

function HeaderLogo(props) {
    // console.log('THESE PROPS', props)
    if (props.logoData.asset.extension == 'svg') {
        return <div className="header__logo">
            <img src={props.logoData.asset.url} alt={'logo'}/>
        </div>
        
    }
    else {
        return <div className="header__logo">
            <Img fluid={props.logoData.asset.fluid} alt={'logo'}/>
        </div>
    }
    
}

export default function FourOhFourPage(props) {
    // console.log(props)
    const data = props.data.siteSettings.nodes[0];
    return (
        <FourOhFourStyles>
            <HeaderLogo logoData={data.logo} />
            <p>Oops sorry, that page doesn't exist </p>
            <Link to="/">Click here to return home</Link>
        </FourOhFourStyles>
    );
}

export const query = graphql`
query {
    siteSettings: allSanitySiteSettings {
        nodes {
          logo {
            asset {
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
              extension
              url
            }
          }
          
        }
    }
}
  
`